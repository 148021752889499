import { stringify as qsStringify } from "query-string"

import type { CreateTokenRes, RefreshTokenRes } from "types/api.types"
import type {
  LoginOAuthReq,
  LoginOAuthRes,
  LoginReq,
  LoginRes,
  ValidateOAuthReq,
  ValidateOAuthRes,
} from "types/auth.types"
import type {
  ChangePasswordReq,
  ChangePasswordRes,
  RecoverReq,
  ResetPasswordReq,
  ResetPasswordRes,
} from "types/password.types"
import type {
  CreateUserReq,
  CreateUserRes,
  ActivateUserReq,
  ActivateUserRes,
} from "types/users.types"

import { api } from "./base"

const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    activateUser: builder.mutation<ActivateUserRes, ActivateUserReq>({
      query: ({ username, secret, password }) => ({
        url: `/v1/users/${username}/activation`,
        method: "POST",
        body: { secret, password },
      }),
    }),
    changePassword: builder.mutation<ChangePasswordRes, ChangePasswordReq>({
      query: ({ username, current_password, new_password }) => ({
        url: `/v1/users/${encodeURIComponent(username)}/password`,
        method: "PUT",
        body: {
          current_password,
          new_password,
        },
      }),
    }),
    createUser: builder.mutation<CreateUserRes, CreateUserReq>({
      query: ({ orgId, username, brand }) => ({
        url: "/v1/enterprise_users",
        method: "POST",
        body: {
          brand,
          org_id: orgId,
          username,
        },
      }),
      invalidatesTags: (_result, _error, { orgId }) => [
        { type: "User", id: `Organization(${orgId})` },
      ],
    }),
    permanentToken: builder.mutation<CreateTokenRes, void>({
      query: () => ({
        url: "/v1/permanent_token",
        method: "POST",
      }),
    }),
    refreshToken: builder.query<RefreshTokenRes, null>({
      query: () => ({
        url: "/v1/tokens/refresh",
        method: "POST",
      }),
    }),
    resetPassword: builder.mutation<ResetPasswordRes, ResetPasswordReq>({
      query: ({ secret, username, password }) => ({
        url: `/v1/users/${encodeURIComponent(username)}/recover`,
        method: "PUT",
        body: { guid: secret, new_password: password },
      }),
    }),
    login: builder.mutation<LoginRes, LoginReq>({
      query: (credentials) => ({
        url: "/v1/tokens",
        method: "POST",
        body: credentials,
      }),
    }),
    loginOAuth: builder.mutation<LoginOAuthRes, LoginOAuthReq>({
      query: ({ provider, brand }) => ({
        url: "/v1/tokens/oauth/authorize",
        method: "POST",
        body: { provider, brand },
      }),
    }),
    validateOAuth: builder.mutation<ValidateOAuthRes, ValidateOAuthReq>({
      query: (oAuthData) => ({
        url: "/v1/tokens/oauth/verify",
        method: "POST",
        body: qsStringify(oAuthData),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }),
    }),
    sendRecoverEmail: builder.mutation<boolean | null, RecoverReq>({
      query: ({ username, brand }) => ({
        url: `/v1/users/${encodeURIComponent(username)}/recover`,
        method: "POST",
        body: { email: username, brand },
      }),
    }),
    // Not in use
    register: builder.mutation({
      query: (userData) => ({
        url: "/v1/users",
        method: "POST",
        body: userData,
      }),
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),
  }),
})

export const {
  useActivateUserMutation,
  useChangePasswordMutation,
  useCreateUserMutation,
  usePermanentTokenMutation,
  useRefreshTokenQuery,
  useResetPasswordMutation,
  useLoginMutation,
  useLoginOAuthMutation,
  useValidateOAuthMutation,
  useSendRecoverEmailMutation,
} = usersApi
