import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Grid from "@mui/material/Grid"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { stringify as qsStringify } from "query-string"

import { Divider } from "@mui/material"
import { LogoContainer } from "widgets/styled/containers"
import GoogleLogo from "images/googleLogo.svg"
import MicrosoftLogo from "images/microsoftLogo.svg"
import { useLoginMutation, useLoginOAuthMutation } from "features/api"
import { setCredentials } from "features/store/authSlice"
import { DEFAULT_TARGET, IMAGOTYPE, OAUTH_PROVIDERS } from "helpers/utils/constants"
import { getBrand } from "helpers/utils/common"
import ControlledInput from "widgets/common/ControlledInput"
import LoadingButton, { LightLoadingButton } from "widgets/common/LoadingButton"
import ControlledPasswordInput from "widgets/common/ControlledPasswordInput"
import CustomLink from "widgets/common/CustomLink"

import type { LocationState } from "types/locationState.types"

function Login() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const locationState = location?.state
    ? (location.state as LocationState)
    : ({} as LocationState)
  const loginFormMethods = useForm({
    mode: "all",
    defaultValues: { username: "", password: "" },
  })
  const {
    handleSubmit: handleLoginSubmit,
    getValues: getLoginValues,
    formState: { isSubmitted: isLoginSubmitted },
  } = loginFormMethods

  const loginGoogleFormMethods = useForm({ mode: "all" })
  const loginMicrosoftFormMethods = useForm({ mode: "all" })
  const {
    handleSubmit: handleGoogleSubmit,
    formState: { isSubmitted: isGoogleSubmitted },
  } = loginGoogleFormMethods
  const {
    handleSubmit: handleMicrosoftSubmit,
    formState: { isSubmitted: isMicrosoftSubmitted },
  } = loginMicrosoftFormMethods

  const [login, { isLoading: isLoadingLogin }] = useLoginMutation()
  const [loginOAuth, { isLoading: isLoadingLoginOAuth }] = useLoginOAuthMutation()
  const [oAuthProvider, setOAuthProvider] = useState<
    keyof typeof OAUTH_PROVIDERS | null
  >()

  const [loginErrorMessage, setLoginErrorMessage] = useState<string | null>(null)
  const [loginOAuthErrorMessage, setLoginOAuthErrorMessage] = useState<string | null>(
    null,
  )

  const onLoginSubmit = async () => {
    const account = {
      username: getLoginValues("username"),
      password: getLoginValues("password"),
    }
    return login(account)
      .unwrap()
      .then((user) => {
        dispatch(
          setCredentials({
            user: account.username,
            token: user.access_token,
            code: user.code,
            globalRole: user.role,
          }),
        )
        const target = locationState.redirectedFrom ?? DEFAULT_TARGET
        navigate(target, { state: { redirectedFrom: location.pathname } })
      })
      .catch((error) => {
        /* TODO: The error messages should be the ones sent by the API.
         * At the moment, it is sending some very long messages that are not user friendly.
         *
         * It would be a good practice to always use the backend error messages. If the backend
         * does not send them in the user's language, we could add a messageKey in the response,
         * that we can use as the translation key. See sentrisense/cloud#1948.
         */
        const body = error.data ?? {}
        if (
          error.status === 401 &&
          (body?.code === "E_WEAK_PASS" || body?.code === "E_EXPIRED_PASS")
        ) {
          const query = qsStringify({ reason: body.code })
          navigate(`/users/${account.username}/modify-password?${query}`)
        }
        const message =
          error.status === 404 || error.status === 401 || !error.data
            ? t("login.EMAIL_PASS_INVALID")
            : error.data.message
        setLoginErrorMessage(message)
      })
  }
  const loginWithOAuth = (provider: keyof typeof OAUTH_PROVIDERS) => async () => {
    setOAuthProvider(provider)
    setLoginOAuthErrorMessage(null)
    const loginOAuthData = {
      provider: OAUTH_PROVIDERS[provider],
      brand: getBrand(window.location.href),
    }
    loginOAuth(loginOAuthData)
      .unwrap()
      .then((oauthData) => {
        window.location.href = oauthData.redirect_uri
      })
      .catch((error) => {
        setLoginOAuthErrorMessage(error.data.message)
      })
  }

  return (
    <Card
      sx={{ maxWidth: 360, minWidth: 280, width: "50%", textAlign: "center" }}
      elevation={4}
    >
      <LogoContainer disableGutters>
        <Box component="img" src={IMAGOTYPE} sx={{ width: "60%" }} />
      </LogoContainer>
      <CardContent sx={{ px: "7%" }}>
        <Typography variant="body2" component="p" align="center" sx={{ mb: 2, mt: 1 }}>
          {t("login.SIGN_IN_TO_CONTINUE")}
        </Typography>
        <form name="formLogin" onSubmit={handleLoginSubmit(onLoginSubmit)} noValidate>
          <FormProvider {...loginFormMethods}>
            <Stack spacing={2}>
              <ControlledInput
                size="small"
                type="email"
                placeholder=""
                label="Email"
                variant="outlined"
                name="username"
                rules={{
                  required: t("generic.FIELD_REQUIRED"),
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: t("generic.FIELD_INVALID_EMAIL"),
                  },
                }}
                inputLabelProps={{ shrink: true }}
              />
              <ControlledPasswordInput
                size="small"
                placeholder=""
                label={t("login.PASSWORD")}
                variant="outlined"
                name="password"
                rules={{
                  required: t("generic.FIELD_REQUIRED"),
                }}
                inputLabelProps={{ shrink: true }}
              />
            </Stack>
            <Grid container justifyContent="flex-end" alignItems="center" sx={{ mb: 3 }}>
              <Grid item>
                <CustomLink href="/accounts/recover" underlineHover variant="body2">
                  {t("login.FORGOT_YOUR_PASSWORD")}
                </CustomLink>
              </Grid>
            </Grid>
            {isLoginSubmitted && (
              <Typography color="error" variant="body2" component="p" align="right">
                {loginErrorMessage}
              </Typography>
            )}
            <LoadingButton
              loading={isLoadingLogin}
              variant="contained"
              styles={{ width: "100%", mt: 1, mb: 1 }}
            >
              {t("login.LOGIN")}
            </LoadingButton>
          </FormProvider>
        </form>
        {/* Disable register
          <Typography variant="body3" align="center" component="p" sx={{ mt: 1 }}>
            Don&apos;t have an account?&nbsp;
            <Link href="register" variant="body3">
              Register Now
            </Link>
          </Typography>
          */}

        <Divider sx={{ borderColor: (theme) => theme.palette.neutral[400], my: 2 }} />

        <form
          name="formLoginGoogle"
          onSubmit={handleGoogleSubmit(loginWithOAuth("GOOGLE"))}
          noValidate
        >
          <FormProvider {...loginGoogleFormMethods}>
            <LightLoadingButton
              loading={
                isLoadingLoginOAuth &&
                oAuthProvider === "GOOGLE" &&
                !loginOAuthErrorMessage
              }
              type="submit"
              variant="contained"
              styles={{ gap: "12px" }}
              icon={
                <Box component="img" src={GoogleLogo} alt={"google"} width={"20px"} />
              }
              name="provider"
              value="google"
            >
              {t("login.LOGIN_WITH_GOOGLE")}
            </LightLoadingButton>
            {oAuthProvider === "GOOGLE" &&
              isGoogleSubmitted &&
              !!loginOAuthErrorMessage && (
                <Typography color="error" variant="body2" component="p">
                  {loginOAuthErrorMessage}
                </Typography>
              )}
          </FormProvider>
        </form>
        <form
          name="formLoginMicrosoft"
          onSubmit={handleMicrosoftSubmit(loginWithOAuth("MICROSOFT"))}
          noValidate
        >
          <FormProvider {...loginMicrosoftFormMethods}>
            <LightLoadingButton
              loading={
                isLoadingLoginOAuth &&
                oAuthProvider === "MICROSOFT" &&
                !loginOAuthErrorMessage
              }
              type="submit"
              variant="contained"
              styles={{ gap: "12px" }}
              icon={
                <Box
                  component="img"
                  src={MicrosoftLogo}
                  alt={"microsoft"}
                  width={"20px"}
                />
              }
              name="provider"
              value="microsoft"
            >
              {t("login.LOGIN_WITH_MICROSOFT")}
            </LightLoadingButton>
            {oAuthProvider === "MICROSOFT" &&
              isMicrosoftSubmitted &&
              !!loginOAuthErrorMessage && (
                <Typography color="error" variant="body2" component="p">
                  {loginOAuthErrorMessage}
                </Typography>
              )}
          </FormProvider>
        </form>
      </CardContent>
    </Card>
  )
}

export default Login
