import { api } from "./base"
import { conductorsApi } from "./conductors"
import { powerlinesApi } from "./powerlines"
import { spansApi } from "./spans"
import { towersApi } from "./towers"

export const {
  // dashboard and telemetry related
  useCellDataQuery,
  useCellViewQuery,
  useDashboardByDeviceQuery,
  useDeviceTelemetryQuery,
  useLastLocationsQuery,
  useLazyDeviceTelemetryQuery,

  // orgs related
  useOrgsQuery,
  useOrgUsersQuery,
  useUpdateOrgNameMutation,
  useUpdateOrgSettingsMutation,
} = api
export { api } from "./base"

export {
  useAddDeviceAlertSettingMutation,
  useAlertsQuery,
  useLimitedAlertsQuery,
  useDeleteAlertSettingMutation,
  useDeviceAlertTypesQuery,
  useGroupAlertsInfoQuery,
  useMarkAlertAsViewedMutation,
  useOrgAlertSettingsQuery,
  useOrgAlertSettingsStatesQuery,
  useUpdateDeviceAlertSettingMutation,
  useUpdateOrgAlertStateMutation,
} from "./alerts"

export const { useConductorsQuery, useConductorQuery } = conductorsApi

export {
  useActivateDeviceMutation,
  useCreateDeviceMutation,
  useDeleteDeviceMutation,
  useDeviceQuery,
  useDevicesQuery,
  useSellDeviceMutation,
  useUpdateDeviceNameMutation,
  useUpdateLocationMutation,
} from "./devices"

export {
  useAddDeviceToGroupMutation,
  useCreateGroupMutation,
  useDeleteGroupMutation,
  useDeviceGroupQuery,
  useDeviceGroupsQuery,
  useRemoveDeviceFromGroupMutation,
  useRemoveDevicesFromGroupMutation,
  useUpdateDeviceGroupNameMutation,
} from "./deviceGroups"

export const { usePowerlinesQuery, usePowerlineQuery } = powerlinesApi

export const { usePowerlineSpansQuery, useSpansQuery, useSpanQuery } = spansApi

export const { usePowerlineTowersQuery, useTowersQuery, useTowerQuery } = towersApi

export {
  useActivateUserMutation,
  useChangePasswordMutation,
  useCreateUserMutation,
  usePermanentTokenMutation,
  useRefreshTokenQuery,
  useResetPasswordMutation,
  useLoginMutation,
  useLoginOAuthMutation,
  useValidateOAuthMutation,
  useSendRecoverEmailMutation,
} from "./users"
